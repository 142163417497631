import React, { useEffect, useState, useContext } from 'react'
import {Button, ChargingButtons, Dropdown, Input, Table, IntensityTable} from './form-components'
import FormPagination from './form-components/FormPagination';
import { CarContext } from './Home'
import {
    fetchManufacturers,
    fetchModels,
    fetchCarbonIntensity,
    fetchCalculate,
    fetchDuration,
} from '../utils';
import { Link } from 'react-router-dom';

function Form() {
// Context states to pass between form steps.
const { updateCarModel, selectedTime, selectedTimeItem, handleImage, handleTimeSelect, setIsModel, selectedChargeSpeed } = useContext(CarContext);


// Form states to manage form steps and validation.
const [step,setStep] =  useState(1);
const [price, setPrice] = useState();
const [model, setModel] = useState('');

// Option states to pass to components.
const [manufacturerOptions, setManufacturerOptions] = useState();
const [modelOptions, setModelOptions] = useState();
const [IntensityData, setIntensityData] = useState();
const [durationData, setDurationData] = useState();
const [calculate, setCalculate] = useState();


// Manufacturers fetch method, only loads one time on load.
useEffect(() => {
    fetchManufacturers().then(data => {
        setManufacturerOptions(data);
    })
}, []);

// Carbon intensity fetch method, only loads on second form step.
useEffect(() => {
    step == 2 && (
        fetchCarbonIntensity(model).then(data => {
            setIntensityData(data);
        })
    )
}, [step]);

useEffect(() => {
    step == 3 && (
        fetchCalculate(price ? price : 0.34, model, selectedTime).then(data => {
            setCalculate(data)
        })
    )
}, [step, price])

const handleManufacturerChange = (event) => {
    // Value of each dropdown option (event.target) is manufacturer_id
    const manufacturer_id = event.target.value;

    fetchModels(manufacturer_id).then(data => {
        setModelOptions(data);
    })
};

const handleModelChange = (event) => {
    // Value of each dropdown option (event.target) is model_id
    const model_id = event.target.value;

    setModel(model_id);
    updateCarModel(model_id);
    handleImage(model_id);
};

// Managing step, active button and form submit state functions.

const nextStep = () => {
    setStep(step + 1);
};

const prevStep = () => {
    setStep(step - 1);
};

const handleFormSubmit = () => {
    nextStep();
}

const fetchCalculation = (e) => {
    e.preventDefault();
    fetchCalculate(price ? price : 0.34, model, selectedTime).then(data => {
        setCalculate(data)
    })
}

  return (
    <div className='form-container'>
        {
            step == 1 && (
                <>
                    <form onSubmit={handleFormSubmit}>
                        <p><span>STEP ONE</span></p>
                        <h1>Please select the electric car you would like to charge.</h1>
                        <Dropdown
                            required
                            placeholder="Select EV Manufacturer"
                            options={manufacturerOptions}
                            onChange={handleManufacturerChange}
                            type='manufacturers'
                        />
                        <Dropdown
                            required
                            placeholder="Select EV Model"
                            options= {modelOptions}
                            onChange={handleModelChange}
                            type='models'
                        />
                        <Button
                            type="Submit"
                            variant='primary'
                            label="Get Started"
                        />
                    </form>
                    <FormPagination step={step} />
                </>
            )
        }
        {
            step == 2 && (
                <>
                    <div className='step-2-container'>
                        <p><span>STEP TWO</span></p>
                        <h1>Select the time of the day you'd like to charge your vehicle.</h1>
                        <IntensityTable data={IntensityData} />
                        <div className='navigation-button-group'>
                            {
                                selectedTime &&
                                <Button
                                    variant='primary'
                                    label="Next"
                                    onClick={nextStep}
                                />
                            }
                            <Button
                                variant='secondary'
                                label="Back"
                                onClick={prevStep}
                            />
                        </div>
                    </div>
                    <FormPagination step={step} />
                </>
            )
        }
        {
            step == 3 && (
                <>
                    <form onSubmit={fetchCalculation} className='final-step'>
                        <p><span>STEP THREE</span></p>
                        <h1>Select location and charger to calculate your carbon content.</h1>
                        <ChargingButtons data={IntensityData} />
                        { selectedChargeSpeed === 'home_charge' ? (
                            <>
                                <Input
                                    value={price}
                                    onChange={e => setPrice(e.target.value)}
                                    placeholder={price ? '' : 'National average £0.34p per kWh (or enter new price)'}
                                />
                            </>
                        ) : ''}
                        {
                            calculate && (
                                <Table
                                    status={selectedChargeSpeed}
                                    data={calculate}
                                    selected_time_item={selectedTimeItem}
                                />
                            )
                        }
                        <div className='navigation-button-group'>
                            <Button
                                variant='primary'
                                label="Recalculate"
                                onClick={fetchCalculation}
                            />
                            <Button
                                variant='secondary'
                                label="Back"
                                onClick={prevStep}
                            />
                        </div>
                        <p className='disclaimer'>
                            Disclaimer: Charging speeds are estimates and are based on a number of assumptions so should only be used for illustrative purposes.
                            The estimates of carbon emissions for ICE vehicles are derived from the 2023 Fuel Consumption Guide published by Natural Resources Canada.
                            <br />
                            {/*<Link className='link' to='/faq'>FAQ</Link>*/}
                        </p>
                    </form>
                    <FormPagination step={step} />
                </>
            )
        }
    </div>
  )
}

export default Form
