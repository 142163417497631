export default async function fetchManufacturers() {
    try {
      const response = await fetch(`${window._env_.REACT_APP_API_URL}/car/manufacturers/`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    } catch (error) {
      throw new Error('Error fetching data from the API');
    }
}
