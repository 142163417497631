import React, {useState, useContext, useEffect} from 'react'
import {Button} from '../form-components'
import {CarContext} from "../Home";

function ChargingButtons({data}) {
    const {handleChargeSpeed, selectedChargeSpeed, selectedTimeItem} = useContext(CarContext);

    let first_date = Object.keys(data)[0];
    let first_time = Object.keys(data[first_date])[0];
    let charge_item = data[first_date][first_time];

    let selected_has_home = true;
    let selected_has_fast = true;
    let selected_has_rapid = true;
    if (selectedTimeItem) {
        selected_has_home = selectedTimeItem.hasOwnProperty("home_charge")
        selected_has_fast = selectedTimeItem.hasOwnProperty("fast_charge")
        selected_has_rapid = selectedTimeItem.hasOwnProperty("rapid_charge")
    }


    return (
        <div className='button-group'>
            <Button
                variant="selector"
                label="Home Charge (7kw)"
                status={selectedChargeSpeed === 'home_charge' ? 'active' : ''}
                onClick={() => handleChargeSpeed('home_charge')}
                disabled={!(charge_item.hasOwnProperty("home_charge") && selected_has_home)}
            />
            <Button
                variant="selector"
                label="Fast Charge (50kw)"
                status={selectedChargeSpeed === 'fast_charge' ? 'active' : ''}
                onClick={() => handleChargeSpeed('fast_charge')}
                disabled={!(charge_item.hasOwnProperty("fast_charge") && selected_has_fast)}
            />
            <Button
                variant="selector"
                label="Rapid Charge (150kw)"
                status={selectedChargeSpeed === 'rapid_charge' ? 'active' : ''}
                onClick={() => handleChargeSpeed('rapid_charge')}
                disabled={!(charge_item.hasOwnProperty("rapid_charge") && selected_has_rapid)}
            />
        </div>
    )
}

export default ChargingButtons
