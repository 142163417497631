export default async function fetchCalculate(price, model, selected_date) {
    try {
      const response = await fetch(`${window._env_.REACT_APP_API_URL}/calculate/?model_id=${model}&selected_date=${selected_date}&price=${price}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    } catch (error) {
    }
}
