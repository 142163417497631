import React from 'react'
import './styles.scss'

function Button({variant,label,status = "", disabled, ...buttonProps}) {
  let class_name = variant+"-button";
  if (disabled) {
    class_name = class_name+"-disabled";
    label += " unavailable"
  }else if (status=== "active"){
    class_name = class_name+"-active";
  }

  return (
    <button className={class_name} disabled={disabled} {...buttonProps}>
        {label}
    </button>
  )
}

export default Button
