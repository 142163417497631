export default async function fetchCarbonIntensity(model) {
    try {
      const response = await fetch(`${window._env_.REACT_APP_API_URL}/intensity/running/?model_id=${model}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    } catch (error) {
      throw new Error('Error fetching data from the API');
    }
}
