import React from 'react'
import './styles.scss'

function Graph() {
  return (
    <div className='graph-container'>
      <img src='graph.svg' />
    </div>
  )
}

export default Graph
