import React, {useState, useContext, useEffect} from 'react'
import {CarContext} from '../Home';
import TimeSlots from '../__data__/time-slots.json';
import {ChargingButtons} from '../form-components'

function formatDate(inputDate) {
    // Create an array of month names
    const monthNames = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    // Parse the input date
    const parts = inputDate.split("-");
    const month = parseInt(parts[1]);
    const day = parseInt(parts[2]);

    // Convert the day to its ordinal form (e.g., 1st, 2nd, 3rd, 4th, etc.)
    let daySuffix;
    if (day === 1 || day === 21 || day === 31) {
        daySuffix = "st";
    } else if (day === 2 || day === 22) {
        daySuffix = "nd";
    } else if (day === 3 || day === 23) {
        daySuffix = "rd";
    } else {
        daySuffix = "th";
    }

    // Format the date
    const formattedDate = `${day}${daySuffix} ${monthNames[month - 1]}`;
    return formattedDate;
}

function IntensityTable({data}) {
    const {handleTimeSelect, selectedChargeSpeed} = useContext(CarContext);
    const [selected_item, setSelectedItem] = useState();

    if (!data || Object.keys(data).length === 0) {
        return <div>Loading intensity data...</div>;
    }

    const SelectTimeSlot = (date, item) => {
        if (item.hasOwnProperty(selectedChargeSpeed)) {
            const selected_date = date + 'T' + item.from + 'Z';
            handleTimeSelect(selected_date, item);
            setSelectedItem(item);
        }
    };

    const itemClassName = (item) => {
        let class_name = "time-slot-cell-unavailable-intensity"
        if (item.hasOwnProperty(selectedChargeSpeed)) {
            class_name = `time-slot-cell-${item[selectedChargeSpeed].index}-intensity`;
        }

        if (selected_item) {
            let selected_charge_item = selected_item[selectedChargeSpeed];

            let itemDate = new Date(item.from_dt);
            let rangeStart = new Date(selected_charge_item.from);
            let rangeEnd = new Date(selected_charge_item.to);
            let selected_index = selected_charge_item.index;
            if (itemDate >= rangeStart && itemDate <= rangeEnd) {
                if (itemDate.toISOString() === rangeStart.toISOString()) {
                    class_name = `time-slot-cell-${selected_index}-intensity-selected`;
                } else {
                    class_name = `time-slot-cell-${selected_index}-intensity-selected-after`;
                }
            }
        }
        return class_name;
    }

    const itemCarbon = (item) => {
        return item.intensity;
    }


  return (
    <>
  <ChargingButtons data={data}/>
  <div className='intensity-table-container'>
  <div className='table-container'>
  <table className='timeslot-table'>
    <thead>
      <th></th>
    </thead>
    <tbody>
      {TimeSlots.map((item) => (
        <tr>
          <td>
            <p>{item}</p>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
  {
    data &&
      <section className='table-section'>
        <table className='intensity-table'>
          <thead>
              <tr>
                {Object.keys(data)?.map((date) => (
                  <th key={date}>{formatDate(date)}</th>
                ))}
              </tr>
          </thead>
          <tbody>
            <tr>
              {Object.keys(data).map((date) => (
                  <td key={date}>
                    {data[date].map((item, index) => (
                      <div
                        onClick={() => SelectTimeSlot(date, item)}
                        className={itemClassName(item)}>
                          {itemCarbon(item)}
                      </div>
                    ))}
                  </td>
              ))}
            </tr>
          </tbody>
        </table>
      </section>
  }
  </div>

            </div>
        </>

    )
}

export default IntensityTable
