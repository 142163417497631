import React, {useState, createContext, useEffect} from 'react';
import './styles.scss'
import Form from './Form'
import {fetchModelImage} from '../utils';
import Logo from "./Logo/Logo";

export const CarContext = createContext();

function Home() {
    const [imageLoaded, setImageLoaded] = useState(false);

    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    const [carModel, setCarModel] = useState();
    const [selectedChargeSpeed, setSelectedChargeSpeed] = useState('home_charge');
    const [selectedTime, setSelectedTime] = useState('');
    const [selectedTimeItem, setSelectedTimeItem] = useState('');
    const [isModel, setIsModel] = useState(false);
    const [image, setImage] = useState('');
    const [imgUrl, setImgUrl] = useState();

    const updateCarModel = (model) => {
        setCarModel(model);
        setIsModel(true);
    };

    const handleChargeSpeed = (charge_speed) => {
        setSelectedChargeSpeed(charge_speed);
        console.log(charge_speed);
    }


    const handleTimeSelect = (selected_time, selected_time_item) => {
        setSelectedTime(selected_time)
        setSelectedTimeItem(selected_time_item)
    }

    const handleImage = (img_url) => {
        setImgUrl(img_url)
    }

    useEffect(() => {
        imgUrl && (
            fetchModelImage(imgUrl).then(data => {
                setImage(data.img)
            })
        )
    }, [imgUrl])

    const contextValue = {
        carModel,
        selectedChargeSpeed,
        selectedTime,
        selectedTimeItem,
        imgUrl,
        handleImage,
        handleChargeSpeed,
        handleTimeSelect,
        updateCarModel,
        setIsModel
    };

    return (
        <div className='container'>
            <CarContext.Provider value={contextValue}>
                <section className='info-section'>
                    <section className={`info-static-panel ${isModel ? 'half-height' : 'full-height'}`}>
                        <p>
                            <span>HOME</span> &#x2022; CHARGING TIME & COST CALCULATOR
                        </p>
                        <h1>Calculate Charge Times & Electricity Costs</h1>
                        <p>
                            Our Charging Time & Cost Calculator allows you to calculate how much it will cost
                            and how long it will take to charge your EV.
                        </p>
                    </section>
                    {
                        isModel && (
                            <section className='info-image-panel'>
                                <img
                                    onLoad={handleImageLoad}
                                    alt="Model"
                                    style={{opacity: imageLoaded ? 1 : 0, transition: 'opacity 0.15s ease-in-out'}}
                                    fetchpriority="high"
                                    src={image}/>
                            </section>
                        )
                    }
                </section>
                <section className='form-section'>
                    <div style={{alignSelf:'flex-start', margin:20}}> <Logo width={50} height={50}/> </div>
                    <Form/>
                </section>
            </CarContext.Provider>
        </div>
    )
}

export default Home;
