import React, { useState } from 'react'
import './styles.scss'

function Table({status, data, selected_time_item}) {

const calculationData = data[status];

// column states
const [columnA, setColumnA] = useState('tesla');
const [columnB, setColumnB] = useState('ubitricity');
const [columnC, setColumnC] = useState('ionity');
const [columnD, setColumnD] = useState('instavolt');

  return (
    <>
    <div className='desktop-table'>
        {status == 'home_charge' ? (
            <table className='form-table'>
                <tbody>
                    <tr className='info-rows'>
                        <td>Charging Time</td>
                        <td>{calculationData.duration}</td>
                    </tr>
                    <tr>
                        <td>kWh added</td>
                        <td>{calculationData.kwh_added}</td>
                    </tr>
                    <tr>
                        <td>Charging Cost</td>
                        <td>
                            {calculationData.cost}
                        </td>
                    </tr>
                    <tr>
                        <td>Carbon Content</td>
                        <td>{selected_time_item[status].total_carbon}</td>
                    </tr>
                    <tr>
                        <td>ICE Equivalent</td>
                        <td>{calculationData.ice_equivalent}</td>
                    </tr>
                </tbody>
            </table>
        ) : (
            <table className='form-table'>
                <thead>
                    <tr>
                        <th></th>
                        <th>
                            <select className='form-dropdown' value={columnA} onChange={(e) => setColumnA(e.target.value)}>
                                {Object.keys(calculationData.cost).map((item) => (
                                    <option value={item}>{item}</option>
                                ))}
                            </select>
                        </th>
                        <th>
                            <select className='form-dropdown' value={columnB} onChange={(e) => setColumnB(e.target.value)}>
                                {Object.keys(calculationData.cost).map((item) => (
                                    <option>{item}</option>
                                ))}
                            </select>
                        </th>
                        <th>
                            <select className='form-dropdown' value={columnC} onChange={(e) => setColumnC(e.target.value)}>
                                <option value='' selected disabled>Networks</option>
                                {Object.keys(calculationData.cost).map((item) => (
                                    <option>{item}</option>
                                ))}
                            </select>
                        </th>
                        <th>
                            <select className='form-dropdown' value={columnD} onChange={(e) => setColumnD(e.target.value)}>
                                <option value='' selected disabled>Networks</option>
                                {Object.keys(calculationData.cost).map((item) => (
                                    <option>{item}</option>
                                ))}
                            </select>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Charging Time</td>
                        <td>{columnA && (calculationData.duration)}</td>
                        <td>{columnB && (calculationData.duration)}</td>
                        <td>{columnC && (calculationData.duration)}</td>
                        <td>{columnD && (calculationData.duration)}</td>
                    </tr>
                    <tr>
                        <td>kWh added</td>
                        <td>{columnA && (calculationData.kwh_added)}</td>
                        <td>{columnB && (calculationData.kwh_added)}</td>
                        <td>{columnC && (calculationData.kwh_added)}</td>
                        <td>{columnD && (calculationData.kwh_added)}</td>
                    </tr>
                    <tr>
                        <td>Charging Cost</td>
                        <td>{columnA && (`£${calculationData.cost[columnA]}`)}</td>
                        <td>{columnB && (`£${calculationData.cost[columnB]}`)}</td>
                        <td>{columnC && (`£${calculationData.cost[columnC]}`)}</td>
                        <td>{columnD && (`£${calculationData.cost[columnD]}`)}</td>
                    </tr>
                    <tr>
                        <td>Carbon Content</td>
                        <td>{columnA && (selected_time_item[status].total_carbon)}</td>
                        <td>{columnB && (selected_time_item[status].total_carbon)}</td>
                        <td>{columnC && (selected_time_item[status].total_carbon)}</td>
                        <td>{columnD && (selected_time_item[status].total_carbon)}</td>
                    </tr>
                    <tr>
                        <td>ICE Equivalent</td>
                        <td>{columnA && (calculationData.ice_equivalent)}</td>
                        <td>{columnB && (calculationData.ice_equivalent)}</td>
                        <td>{columnC && (calculationData.ice_equivalent)}</td>
                        <td>{columnD && (calculationData.ice_equivalent)}</td>
                    </tr>
                </tbody>
            </table>
        )}
    </div>
    <div className='mobile-table'>
        {
            status == 'home_charge' ? (
                <table className='form-table'>
                    <thead>
                        <th>
                            <td>Charging Time</td>
                        </th>
                        <th>
                            <td>kWh added</td>
                        </th>
                        <th>
                            <td>Charging Cost</td>
                        </th>
                        <th>
                            <td>Carbon Content</td>
                        </th>
                        <th>
                            <td>ICE Equivalent</td>
                        </th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{calculationData.duration}</td>
                            <td id='home-charge-kwh'>{calculationData.kwh_added}</td>
                            <td>£{calculationData.cost}</td>
                            <td>{selected_time_item[status].total_carbon}</td>
                            <td>{calculationData.ice_equivalent}</td>
                        </tr>
                    </tbody>
                </table>
            ) : (
                <table className='form-table'>
                    <thead>
                        <th></th>
                        <th>
                            Charging time
                        </th>
                        <th>
                            kWh added
                        </th>
                        <th>
                            Charging Cost
                        </th>
                        <th>
                            Carbon Content
                        </th>
                        <th>
                            ICE Equivalent
                        </th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <select className='form-dropdown' value={columnA} onChange={(e) => setColumnA(e.target.value)}>
                                    <option value='tesla' selected disabled>Networks</option>
                                    {Object.keys(calculationData.cost).map((item) => (
                                        <option value={item}>{item}</option>
                                    ))}
                                </select>
                            </td>
                            <td>{columnA && (calculationData.duration)}</td>
                            <td>{columnA && (calculationData.kwh_added)}</td>
                            <td>{columnA && (`£${calculationData.cost[columnA]}`)}</td>
                            <td>{columnA && (selected_time_item[status].total_carbon)}</td>
                            <td>{columnA && (calculationData.ice_equivalent)}</td>
                        </tr>
                        <tr>
                            <td>
                                <select className='form-dropdown' value={columnB} onChange={(e) => setColumnB(e.target.value)}>
                                    <option value='tesla' selected disabled>Networks</option>
                                    {Object.keys(calculationData.cost).map((item) => (
                                        <option>{item}</option>
                                    ))}
                                </select>
                            </td>
                            <td>{columnB && (calculationData.duration)}</td>
                            <td>{columnB && (calculationData.kwh_added)}</td>
                            <td>{columnB && (`£${calculationData.cost[columnB]}`)}</td>
                            <td>{columnB && (selected_time_item[status].total_carbon)}</td>
                            <td>{columnB && (calculationData.ice_equivalent)}</td>
                        </tr>
                        <tr>
                            <td>
                                <select className='form-dropdown' value={columnC} onChange={(e) => setColumnC(e.target.value)}>
                                    <option value='' selected disabled>Networks</option>
                                    {Object.keys(calculationData.cost).map((item) => (
                                        <option>{item}</option>
                                    ))}
                                </select>
                            </td>
                            <td>{columnC && (calculationData.duration)}</td>
                            <td>{columnC && (calculationData.kwh_added)}</td>
                            <td>{columnC && (`£${calculationData.cost[columnC]}`)}</td>
                            <td>{columnC && (selected_time_item[status].total_carbon)}</td>
                            <td>{columnC && (calculationData.ice_equivalent)}</td>
                        </tr>
                        <tr>
                            <td>
                                <select className='form-dropdown' value={columnD} onChange={(e) => setColumnD(e.target.value)}>
                                    <option value='' selected disabled>Networks</option>
                                    {Object.keys(calculationData.cost).map((item) => (
                                        <option>{item}</option>
                                    ))}
                                </select>

                            </td>
                            <td>{columnD && (calculationData.duration)}</td>
                                <td>{columnD && (calculationData.kwh_added)}</td>
                                <td>{columnD && (`£${calculationData.cost[columnD]}`)}</td>
                                <td>{columnD && (selected_time_item[status].total_carbon)}</td>
                                <td>{columnD && (calculationData.ice_equivalent)}</td>
                        </tr>
                    </tbody>
                </table>
            )
        }
    </div>
    </>
  )
}

export default Table
