import React from 'react'
import './styles.scss'

function Input({placeholder = "", ...inputProps}) {
  return (
    <input type='number' min="0" max="10" step='0.01' className='form-input' placeholder={placeholder} {...inputProps}></input>
  )
}

export default Input
