import React from 'react'
import './styles.scss'

function Dropdown({placeholder, options, type, ...selectProps}) {

  return (
    <select className='form-dropdown' {...selectProps} required>
        <option disabled value='' selected >{placeholder}</option>
        {
          type === 'manufacturers' && (
            <>
              {/* replace ManufacturersResponse with options  */}
              {options?.map((option) => (
                <option value={option.manufacturer_id} >{option.brand}</option>
              ))}
            </>
          )
        }
        {
          type === 'models'&& (
            <>
            {/* replace ModelsResponse with options  */}
              {options?.map((option) => (
                <option value={option.model_id} >{option.model_name}</option>
              ))}
            </>
          )
        }
    </select>
  )
}

export default Dropdown
