import React from 'react'

function FormPagination({step}) {
  return (
    <div className='form-pagination'>
        <div className={step === 1 && 'active'}></div>
        <div className={step === 2 && 'active'}></div>
        <div className={step === 3 && 'active'}></div>
    </div>
  )
}

export default FormPagination
