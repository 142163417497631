import React from 'react'
import { Link } from 'react-router-dom'
import './styles.scss'

function FaqPage() {
  return (
    <div className='faq-container'>
        <section className='faq-section'>
            <Link className='link' to='/'>&#60; Back</Link>
            <h1>Title</h1>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sed odio ornare, maximus justo et, mattis lectus. Fusce sed orci at dolor pharetra ornare. Cras vestibulum est leo, non tempor leo bibendum in. Nam vitae arcu nisl. Nam in vulputate justo, at porta justo. Vivamus lobortis porta elit a consequat. Nullam a turpis nisl. Mauris at eros et justo maximus congue. Phasellus gravida augue lectus, at laoreet purus fermentum eu. Integer euismod diam libero, vel finibus erat mollis at. Etiam ut orci rhoncus, imperdiet nulla at, molestie turpis.

                Praesent id eleifend diam. Proin porttitor euismod tellus, vel sagittis sapien mollis non. Praesent eget volutpat massa. Mauris id efficitur ex. Donec volutpat, nisi nec gravida molestie, metus arcu faucibus tellus, eu fringilla mauris elit porttitor dui. Nam fermentum a metus nec ultricies. Etiam massa lectus, venenatis vitae tortor non, rhoncus maximus magna. Vivamus eleifend orci eget dolor rhoncus, ac molestie ex tincidunt. Donec accumsan volutpat quam ut pulvinar.

                Nulla facilisi. Donec feugiat maximus sodales. Sed et ultricies neque, rutrum scelerisque libero. Donec vitae varius risus. Morbi posuere luctus massa, ut elementum tortor semper ac. In nec placerat orci. Phasellus enim mauris, efficitur at venenatis eu, porttitor sed lorem. Vivamus egestas fermentum lectus, vitae finibus tortor congue faucibus. Etiam cursus faucibus scelerisque. Etiam porta quam ante, sagittis dapibus mauris dignissim ut. Vivamus vel augue nec lectus gravida euismod quis vel orci. Pellentesque in neque sagittis, vulputate tortor at, gravida sem. Ut sodales dictum dui. Ut tincidunt magna nec orci pretium, at posuere nulla mollis. Phasellus vestibulum, lorem sed bibendum scelerisque, augue purus finibus odio, sit amet scelerisque lacus lacus et dui. Cras eu sagittis ipsum.
            </p>
        </section>
    </div>
  )
}

export default FaqPage
